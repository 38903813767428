import React, { useState, useEffect } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext'; // Import the Auth context to get the user role

const Home = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const { currentUserStatus, isAdmin, isMarketing } = useAuth(); // Get user role info

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'dashboard_data'));
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  // Role-based dashboard filtering
  const filteredDashboardData = () => {
    if (isAdmin) {
      // Admin: Show all dashboards
      return dashboardData;
    } else if (isMarketing) {
      // Marketing: Show published and pending dashboards
      return dashboardData.filter(item => item.status === 'published' || item.status === 'pending');
    } else {
      // User: Show only published dashboards
      return dashboardData.filter(item => item.status === 'published');
    }
  };

  const handleBannerBrowseNav = () => {
    navigate(`/browse`);
  };

  return (
    <div className="home container">
      <div className="banner-box">
        <div className="banner-content">
          <div>
            <h1>Explore COMMB 360°</h1>
            <h3>Driving innovation in out-of-home with powerful analytics</h3>
            <button onClick={handleBannerBrowseNav} className="primary-button">Browse all</button>
          </div>
          <div>
            <hr />
          </div>
        </div>
      </div>

      <section className="section">
        <h2>Recently Published Dashboard</h2>
        <div className="card-grid">
          {filteredDashboardData().map((item) => (
            <DashboardCard
              key={item.reportId}
              reportId={item.reportId}
              name={item.name}
              thumbnail={item.thumbnail}
              publicationDate={item.publicationDate}
              description={item.description}
              reportType={item.reportType}
              isPopular={item.isPopular}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
