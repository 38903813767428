import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SideNav.css';
import { useAuth } from '../../context/AuthContext';
import { doSignOut } from '../../auth';
import analytics from '../../assets/menu/analytics.png';
import upload from '../../assets/menu/cloud-computing.png';
import request from '../../assets/menu/interview.png';
import code from '../../assets/menu/password (1).png';
import home from '../../assets/menu/home.png';
import browse from '../../assets/menu/find.png';
import save from '../../assets/menu/save-instagram.png';
import faq from '../../assets/menu/help.png';
import setting from '../../assets/menu/settings.png';
import feedback from '../../assets/menu/feedback.png';

const SideNav = () => {
  const { currentUser, isAdmin } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    doSignOut()
      .then(() => {
        navigate('/');
      })
      .catch(error => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <nav className="sidenav">
      <div className="sidenav-top">
        {currentUser && isAdmin && (
          <section className="sidenav-section">
            <h2>Admin</h2>
            <ul>
              <Link to="/analytics">
                <li>
                  <img src={analytics} alt="Analytics" />
                  <span>Analytics</span>
                </li>
              </Link>
              <Link to="/upload">
                <li>
                  <img src={upload} alt="Upload Dashboard" />
                  <span>Upload Dashboard</span>
                </li>
              </Link>
              <Link to="/access">
                <li>
                  <img src={request} alt="Access Requests" />
                  <span>Access Requests</span>
                </li>
              </Link>
              <Link to="/invite">
                <li>
                  <img src={code} alt="Generate Invite Codes" />
                  <span>Generate Invite Codes</span>
                </li>
              </Link>
              <Link to="/viewcodes">
                <li>
                  <img src={code} alt="Manage Invite Codes" />
                  <span>Manage Invite Codes</span>
                </li>
              </Link>
            </ul>
          </section>
        )}

        <section className="sidenav-section">
          <h2>Reports</h2>
          <ul>
            <Link to="/">
              <li>
                <img src={home} alt="Home" />
                <span>Home</span>
              </li>
            </Link>
            <Link to="/browse">
              <li>
                <img src={browse} alt="Browse Reports" />
                <span>Browse Reports</span>
              </li>
            </Link>
            <Link to="/saved">
              <li>
                <img src={save} alt="Saved Reports" />
                <span>Saved Reports</span>
              </li>
            </Link>
          </ul>
        </section>

        <section className="sidenav-section">
          <h2>Account and Support</h2>
          <ul>
            <Link to="/settings">
              <li>
                <img src={setting} alt="Settings" />
                <span>Settings</span>
              </li>
            </Link>
            <Link to="/faqs">
              <li>
                <img src={faq} alt="FAQs" />
                <span>FAQs</span>
              </li>
            </Link>
            <Link to="/feedback">
              <li>
                <img src={feedback} alt="Feedback" />
                <span>Feedback</span>
              </li>
            </Link>
          </ul>
        </section>
      </div>

      <div className="sidenav-bottom">
        {currentUser ? (
          <button onClick={handleLogout} className="logout-button primary-button">
            Logout
          </button>
        ) : (
          <Link to="/login">
            <button className="login-button primary-button">Login</button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default SideNav;
