import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './FAQs.css'; // Ensure to import your CSS file

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null); 
    } else {
      setActiveIndex(index);
    }
  };
  
  const faqItems = [
    {
      question: 'What does this app do?',
      answer: 'This app is designed to transform how COMMB shares its research and analytics, making data more accessible and digestible for audiences in the OOH (Out-of-Home) industry.'
    },
    {
      question: 'What can I do with dashboards?',
      answer: 'With the dashboards, you can use dynamic filters to tailor insights to your specific needs, drill down into data, export individual visualizations as PNG, or export the entire dashboard as PDF, Excel, or PNG. The dashboards are easily shareable and can be presented to others seamlessly.'
    },
    {
      question: 'Are the insights always accurate?',
      answer: 'While these findings provide valuable insights, they should be interpreted in the context of their respective methodologies and inherent limitations. Please note that when the sample size is below 100, the results may not be statistically significant, and conclusions drawn from such small samples may not accurately reflect larger trends. Interpret with caution.'
    }
  ];
  

  return (
    <div className="accordion container">
      <div className="wrapper">
        <h2>Frequently Asked Questions</h2>
        {faqItems.map((item, index) => (
          <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="accordion-title" onClick={() => toggleAccordion(index)}>
              {item.question}
              <FontAwesomeIcon icon={activeIndex === index ? faAngleUp : faAngleDown} className="accordion-icon" />
            </div>
            <div className="accordion-content">
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
