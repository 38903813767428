import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust import as needed
import DashboardCard from '../../components/DashboardCard/DashboardCard';

const SearchResults = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const [searchResults, setSearchResults] = useState([]);
  const [filterType, setFilterType] = useState('all'); // Adding filter option
  const [sortOrder, setSortOrder] = useState('asc'); // Adding sort option
  const [reportTypes, setReportTypes] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('q') || '';

  useEffect(() => {
    const fetchData = async () => {
      const dashboardCollectionRef = collection(db, 'dashboard_data');
      const snapshot = await getDocs(dashboardCollectionRef);
      const fetchedData = snapshot.docs.map((doc) => ({
        reportId: doc.id,
        ...doc.data(),
      }));

      const uniqueTypes = [...new Set(fetchedData.map((item) => item.reportType))];
      setReportTypes(uniqueTypes);
      let filteredData = fetchedData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (filterType !== 'all') {
        filteredData = filteredData.filter(item => item.reportType === filterType);
      }

      filteredData = filteredData.sort((a, b) => {
        const aDate = new Date(a.publicationDate);
        const bDate = new Date(b.publicationDate);
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      });

      setSearchResults(filteredData);
    };

    fetchData();
  }, [searchQuery, filterType, sortOrder]);

  return (
    <div className="search-results container">
      <button onClick={() => navigate('/')} className="back-button">
        <span>←</span> Back to Home
      </button>
      <h2>Search Results for "{searchQuery}"</h2>
      <section className="section">
        {/* <div className="filter-sort">
          <div>
            <label htmlFor="reportTypeFilter">Filter by Report Type:</label>
            <select
              id="reportTypeFilter"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="all">All</option>
              {reportTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="sortOrder">Sort by Date:</label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="asc">Oldest First</option>
              <option value="desc">Newest First</option>
            </select>
          </div>
        </div> */}

        <div className="card-grid">
          {searchResults.length > 0 ? (
            searchResults.map((item) => (
              <DashboardCard
                key={item.reportId}
                reportId={item.reportId}
                name={item.name}
                thumbnail={item.thumbnail}
                publicationDate={item.publicationDate}
                description={item.description}
                reportType={item.reportType}
                isPopular={item.isPopular}
              />
            ))
          ) : (
            <p>No results found for "{searchQuery}".</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SearchResults;
