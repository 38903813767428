import React, { useState, useEffect } from 'react';
import './Browse.css'; 
import DashboardCard from '../../components/DashboardCard/DashboardCard'; 
import { db } from '../../firebase'; 
import { collection, getDocs } from 'firebase/firestore';

const Browse = () => {
  const [filterType, setFilterType] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [reportTypes, setReportTypes] = useState([]); // To hold unique report types

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const dashboardCollectionRef = collection(db, 'dashboard_data'); 
        const snapshot = await getDocs(dashboardCollectionRef);
        const fetchedData = snapshot.docs.map(doc => ({
          reportId: doc.id,
          ...doc.data()
        }));

        setData(fetchedData);
        setFilteredData(fetchedData);

        // Extract unique report types
        const uniqueTypes = [...new Set(fetchedData.map(item => item.reportType))];
        setReportTypes(uniqueTypes);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    let updatedData = [...data];

    if (filterType !== 'all') {
      updatedData = updatedData.filter(item => item.reportType === filterType);
    }

    updatedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.publicationDate) - new Date(b.publicationDate);
      } else {
        return new Date(b.publicationDate) - new Date(a.publicationDate);
      }
    });

    setFilteredData(updatedData);
  }, [filterType, sortOrder, data]);

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  return (
    <div className="browse-page container">
      <section className='section'>
        <div className="filter-sort">
          <div>
            <label htmlFor="reportTypeFilter">Filter by Report Type:</label>
            <select
              id="reportTypeFilter"
              value={filterType}
              onChange={handleFilterChange}
            >
              <option value="all">All</option>
              {reportTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="sortOrder">Sort by Date:</label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="asc">Oldest First</option>
              <option value="desc">Newest First</option>
            </select>
          </div>
        </div>
        
        <div className="card-grid">
          {filteredData.map((item) => (
            <DashboardCard
              key={item.reportId}
              reportId={item.reportId}
              name={item.name}
              thumbnail={item.thumbnail}
              publicationDate={item.publicationDate}
              description={item.description}
              reportType={item.reportType}
              isPopular={item.isPopular} 
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Browse;
