import React, { useState, useEffect } from 'react';
import { updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { db } from '../../firebase';
import { doc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { doPasswordChange, doSignInWithEmailAndPassword } from '../../auth';
import './Settings.css';

const Settings = () => {
  const { currentUser, currentUserStatus } = useAuth();
  const [firstName, setFirstName] = useState(currentUserStatus.firstName);
  const [lastName, setLastName] = useState(currentUserStatus.lastName);
  const [email, setEmail] = useState(currentUser.email);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New success message state

  // UseEffect to clear the success or error message after 3 seconds
  useEffect(() => {
    if (successMessage || error) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setError('');
      }, 3000); // Clear message after 3 seconds

      // Cleanup the timer
      return () => clearTimeout(timer);
    }
  }, [successMessage, error]);

  const handleSave = async () => {
    try {
      if (!currentUser) {
        setError('No current user detected.');
        console.error('Error: No current user detected.');
        return;
      }

      console.log('Current user:', currentUser);

      // Fetch user document from Firestore
      const usersRef = collection(db, 'commb_users');
      const q = query(usersRef, where('uid', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error('No matching user document found');
      }

      const userDoc = querySnapshot.docs[0];
      const docId = userDoc.id;

      // Update First Name and Last Name
      if (firstName !== currentUserStatus.firstName || lastName !== currentUserStatus.lastName) {
        await updateDoc(doc(db, 'commb_users', docId), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        });
      }

      // Re-authenticate if email or password changes
      if (email !== currentUser.email || newPassword) {
        console.log('Attempting to re-authenticate user...');
        await promptReauthenticate();
        console.log('Re-authentication successful.');
      }

      // Update Email
      if (email !== currentUser.email) {
        console.log('Updating email in Firebase Authentication...');
        await updateEmail(currentUser, email);
        console.log('Updating email in Firestore...');
        await updateDoc(doc(db, 'commb_users', docId), { email });
      }

      // Update Password
      if (newPassword || confirmNewPassword) {
        if (newPassword !== confirmNewPassword) {
          setError('New passwords do not match');
          setSuccessMessage('');
          return;
        }
        await doSignInWithEmailAndPassword(currentUser.email, currentPassword);
        await doPasswordChange(newPassword);
      }

      // Clear inputs and set success message
      setError('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setSuccessMessage('Settings updated successfully!');
    } catch (error) {
      console.error('Error during settings update:', error);
      setSuccessMessage('');
      setError(`Error updating settings: ${error.message}`);
    }
  };

  const promptReauthenticate = async () => {
    try {
      // Implement your re-authentication logic here
    } catch (error) {
      throw new Error('Please login again before this action');
    }
  };

  return (
    <div className='container'>
      <div className="setting">
        <h2>Settings</h2>
        {error && <p className='settings-error'>{error}</p>}
        {successMessage && <p className='settings-success'>{successMessage}</p>}
        
        <div className='settings-form-group'>
          <div className='settings-name-row'>
            <div className='settings-form-group-name'>
              <label>First Name:</label>
              <input
                type='text'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='settings-form-group-name'>
              <label>Last Name:</label>
              <input
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className='settings-form-group'>
            <label>Current Password:</label>
            <input
              type='password'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              name='current-password-field' 
              id='current-password-field'   
              autoComplete='new-password'   
              required                      
            />
          </div>

          <div className='settings-form-group'>
            <label>New Password:</label>
            <input
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className='settings-form-group'>
            <label>Confirm New Password:</label>
            <input
              type='password'
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>

          <button className='settings-button primary-button' onClick={handleSave}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
