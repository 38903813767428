import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBell, FaGlobe, FaSun, FaMoon, FaUserCircle, FaBars, FaTimes } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { doSignOut } from '../../auth';
import { ThemeContext } from '../../context/ThemeContext';
import './MobileNav.css';
import logo_light from '../../assets/brand/commb-logo.png';
import logo_dark from '../../assets/brand/logo (1).svg';

const MobileNav = () => {
  const { currentUser, isAdmin } = useAuth();
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext); 
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    doSignOut()
      .then(() => {
        navigate('/');
      })
      .catch(error => {
        console.error('Error signing out:', error);
      });
  };

  const handleLoginClick = () => {
    navigate('/login'); 
  };

  return (
    <nav className="mobile-nav">
      <div className="mobile-nav-top">
        <Link to="/">
          <img src={isDarkMode ? logo_dark : logo_light} alt="Logo" />
        </Link>
        <div className="icon" onClick={toggleMenu}>
          {showMenu ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      {showMenu && (
        
        <div className="mobile-nav-menu">
          <div className="mobile-nav-bottom">
            {currentUser ? (
              <button onClick={handleLogout} className="logout-button primary-button">Logout</button>
            ) : (
              <button onClick={handleLoginClick} className="login-button primary-button">Login</button>
            )}
          </div>
          <div className="icons">
            <div className="icon" onClick={toggleDarkMode}>
              {isDarkMode ? <FaMoon title="Dark Mode" /> : <FaSun title="Light Mode" />}
            </div>
            <div className="icon">
              <FaGlobe title="Language Toggle" />
            </div>
            <div className="icon">
              <FaBell />
            </div>
          </div>
          
          <section className="mobile-nav-section">
            <h2>Reports</h2>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/browse">Browse Reports</Link></li>
              <li><Link to="/saved">Saved Reports</Link></li>
            </ul>
          </section>

          {currentUser && isAdmin && (
            <section className="mobile-nav-section">
              <h2>Admin</h2>
              <ul>
                <li><Link to="/analytics">Analytics</Link></li>
                <li><Link to="/upload">Upload Dashboard</Link></li>
                <li><Link to="/access">Access Requests</Link></li>
                <li><Link to="/invite">Generate Invite Codes</Link></li>
                <li><Link to="/viewcodes">Manage Invite Codes</Link></li>
              </ul>
            </section>
          )}

          <section className="mobile-nav-section">
            <h2>Account and Support</h2>
            <ul>
              <li><Link to="/settings">Settings</Link></li>
              <li><Link to="/faqs">FAQs</Link></li>
              <li><Link to="/feedback">Feedback</Link></li>
            </ul>
          </section>

        </div>
      )}
    </nav>
  );
}

export default MobileNav;
