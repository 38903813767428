import React, { useEffect, useState } from 'react';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, doc, getDocs } from 'firebase/firestore';

const Saved = () => {
  const { currentUserStatus } = useAuth();
  const [savedReports, setSavedReports] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');
  const [reportTypes, setReportTypes] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUserStatus && currentUserStatus.isVerified) {
        try {
          // Fetch saved reports
          const userId = currentUserStatus.uid;
          const userRef = doc(db, 'commb_users', userId);
          const savedReportsCollectionRef = collection(userRef, 'saved_reports');
          const savedReportsSnapshot = await getDocs(savedReportsCollectionRef);

          const savedReportsData = savedReportsSnapshot.docs.map(doc => ({
            id: doc.id,
            reportId: doc.id 
          }));

          // Fetch dashboard data
          const dashboardCollectionRef = collection(db, 'dashboard_data');
          const dashboardSnapshot = await getDocs(dashboardCollectionRef);
          const fetchedDashboardData = dashboardSnapshot.docs.map(doc => ({
            reportId: doc.id,
            ...doc.data()
          }));

          setDashboardData(fetchedDashboardData);

          // Filter and sort saved reports
          let filteredSavedReports = savedReportsData;
          if (filterType !== 'all') {
            filteredSavedReports = savedReportsData.filter(item => {
              const matchedDashboardItem = fetchedDashboardData.find(dashboardItem => dashboardItem.reportId === item.reportId);
              return matchedDashboardItem && matchedDashboardItem.reportType === filterType;
            });
          }

          const sortedSavedReports = filteredSavedReports.sort((a, b) => {
            const aDate = fetchedDashboardData.find(item => item.reportId === a.reportId)?.publicationDate || new Date();
            const bDate = fetchedDashboardData.find(item => item.reportId === b.reportId)?.publicationDate || new Date();
            if (sortOrder === 'asc') {
              return new Date(aDate) - new Date(bDate);
            } else {
              return new Date(bDate) - new Date(aDate);
            }
          });

          setSavedReports(sortedSavedReports);

          const uniqueTypes = [...new Set(fetchedDashboardData.map(item => item.reportType))];
          setReportTypes(uniqueTypes);

        } catch (error) {
          console.error('Error fetching saved reports and dashboard data:', error);
        }
      }
    };

    fetchData();
  }, [currentUserStatus, filterType, sortOrder]);

  return (
    <div className="saved-reports container">
      <section className='section'>
        <div className="filter-sort">
          <div>
            <label htmlFor="reportTypeFilter">Filter by Report Type:</label>
            <select
              id="reportTypeFilter"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="all">All</option>
              {reportTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="sortOrder">Sort by Date:</label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="asc">Oldest First</option>
              <option value="desc">Newest First</option>
            </select>
          </div>
        </div>
        <div className="card-grid">
          {savedReports.length > 0 ? (
            savedReports.map(report => {
              const matchedDashboardItem = dashboardData.find(item => item.reportId === report.reportId);
              if (matchedDashboardItem) {
                return (
                  <DashboardCard
                    key={matchedDashboardItem.reportId}
                    reportId={matchedDashboardItem.reportId}
                    name={matchedDashboardItem.name}
                    thumbnail={matchedDashboardItem.thumbnail || 'https://placehold.co/300x200/png'} // Fallback URL
                    publicationDate={matchedDashboardItem.publicationDate}
                    description={matchedDashboardItem.description}
                    reportType={matchedDashboardItem.reportType}
                    isPopular={matchedDashboardItem.isPopular} 
                  />
                );
              }
              return null;
            })
          ) : (
            <p>No saved reports found.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Saved;
